import './ChannelList.scss'
import React from 'react'

function ChannelList(props) {
  const { channels } = props

  return (
    <div>
      <div className="legend">
        <div><div className="square vacant" /> Vacant</div>
        <div><div className="square usable" /> Usable Channel*</div>
        <div><div className="square public-safety" /> Public Safety</div>
        <div className="footnote">
          * May have high powered channel above or below or may have low power based on distance.
        </div>
      </div>
      <div className="list-container">
        <table className="channel-list" border="0">
          <thead>
            <tr>
              <th>TV</th>
              <th>MHz</th>
              <th>Call</th>
              <th>Type</th>
              <th>Status</th>
              <th>Location</th>
              <th>ERP</th>
              <th>Dist.</th>
              <th>P<sub>RX</sub></th>
              <th>E<sub>ANT</sub></th>
            </tr>
          </thead>
          <tbody>
            { Object.keys(channels).map((ch_n, i) => {
                const ch = channels[ch_n],
                      rowspan = ch.interfering_stations.length > 1 ? ch.interfering_stations.length : undefined,
                      st1 = ch.interfering_stations.length > 0 && ch.interfering_stations[0],
                      rowClass = ch.status.toLowerCase().replace(/ /g, "-") +
                                (i % 2 === 0 ? " odd" : " even")

                return (
                  <React.Fragment key={ch_n}>
                    <tr className={rowClass}>
                      <td rowSpan={rowspan} className="channel-number">{ch_n}</td>
                      <td rowSpan={rowspan}>{ch.frequencies}</td>
                      <StationCells st={st1} channelStatus={ch.status} />
                    </tr>
                    { ch.interfering_stations.length > 1 &&
                      ch.interfering_stations.slice(1).map((st, j) => {
                        return(
                          <tr
                            key={ch_n + "-" + j}
                            className={rowClass} >
                            <StationCells st={st} />
                          </tr>
                        )
                      }) }
                  </React.Fragment>
                )
              }) }
          </tbody>
        </table>
      </div>
    </div>
  )
}

function StationCells(props) {
  const { st, channelStatus } = props

  return (
    <>
      <td>{st && st.callsign}</td>
      <td>{st && st.type}</td>
      <td className="channel-status">{(st && st.status) || channelStatus}</td>
      <td>{st && st.city}</td>
      <td align="right">{st && st.erp_kw && st.erp_kw + " kW"}</td>
      <td align="right">{st && st.distance}</td>
      <td align="right">{st &&  st.prx_dbm && st.prx_dbm + " dBm"}</td>
      <td align="right">{st && st.e_ant_uv}</td>
    </>
  )
}

export default ChannelList
