import { useRef, useEffect } from 'react'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl'
import './Geocoder.css'

function Geocoder(props) {
  const geocoderElement = useRef(null),
        { onChange } = props

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN
    const gc = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    countries: 'US',
                    types: 'address,region,place,postcode,locality,neighborhood',
                    placeholder: 'address of wireless system',
                  }),
          geoEl = geocoderElement.current

    gc.addTo(geocoderElement.current)
    gc.on('result', (e) => {
      const { result } = e,
            { center } = result,
            [longitude, latitude] = center

      onChange && onChange({ ulon: longitude, ulat: latitude })
    })
    gc.on('clear', () => {
      onChange && onChange(null)
    })

    return(() => {
      geoEl.innerHTML = ''
    })
  }, [onChange])

  return(
    <div className="geocoder" ref={geocoderElement} />
  )
}

export default Geocoder
