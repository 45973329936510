function AdvancedOptions(props) {
  const radiuses = [5, 10, 20, 35, 55, 75, 100, 150, 200, 250],
        { onChange, values } = props

  return (
    <div className="advanced-options">
      <div className="advanced-options-title">
        Advanced Options:
      </div>
      <div className="input-group">
        <label htmlFor="attenuation">Additional Attenuation</label>
        <select
          className="form-control"
          name="attenuation"
          required="required"
          onChange={(e) => onChange && onChange({ attenuation: parseInt(e.target.value) })}
          value={values["attenuation"]} >
          <option value="0">0 db, outdoors little surrounding structures </option>
          <option value="10">-10 db, indoors light structure or near windows </option>
          <option value="20">-20 db, indoors medium structure and away from windows </option>
          <option value="30">-30 db, indoors heavy structure and away from windows (standard)</option>
          <option value="40">-40 db, indoors heavy structure and isolated location</option>
          <option value="50">-50 db, indoors, structure acts as faraday cage </option>
        </select>
      </div>
      <div className="input-group">
        <label htmlFor="radius">Maximum distance of towers (miles)</label>
        <select
          className="form-control"
          name="radius"
          required="required"
          onChange={(e) => onChange && onChange({ radius: parseInt(e.target.value) })}
          value={values["radius"]} >
          { radiuses.map(radius => <option value={radius} key={radius}>{radius}</option>) }
        </select>
      </div>
    </div>
  )
}

export default AdvancedOptions
