import './Recommendations.scss'

const SERIES_NAMES = {
        ewd: "EW-D/EW-DP",
        ewdx: "EW-DX",
        d6000: "Digital 6000" }

function Recommendations(props) {
  const { data, searchParams } = props,
        seriesName = searchParams && searchParams.series && SERIES_NAMES[searchParams.series]

  return (
    <div className="recommendations">
      <div className="series">{seriesName}</div> Recommendations
      { Object.keys(data).map((range) => {
        const rd = data[range]

        if((Number.isInteger(rd.systems) && rd.systems === 0) ||
          Object.values(rd.systems)[0] === 0) return null

        return (
          <div key={range} className="range">
            <div className="range-title">
              {range} Range - {rd.frequences[0]}-{rd.frequences[1]} MHz
            </div>
            { Number.isInteger(rd.systems) &&
              <div className="recommendation-text">
                There are {rd.usable_tv_channels} useable 6 MHz wide DTV channels
                for a possible {rd.systems} wireless microphone systems.
              </div> }
            { !Number.isInteger(rd.systems) &&
              <div className="recommendation-text">
                There are {rd.usable_tv_channels} useable 6 MHz wide DTV channels.
                { Object.keys(rd.systems).map((mode) => {
                    return (
                      <div className="mode" key={mode}>
                        <span className={mode.toLowerCase()}>{mode} Mode</span> allows
                        for a possible {rd.systems[mode]} wireless microphone systems.
                      </div>
                    )
                  })}
              </div> }
          </div>
        )
      })}
    </div>
  )
}

export default Recommendations
