import logo from './sennheiser_logoline.svg'
import './index.scss'

function Header() {
  return (
    <header className="header">
      <div className="wrapper">
        <section>
          <div className="logo">
            <a href="https://en-us.sennheiser.com">
              <img src={logo} alt="Sennheiser logo" />
            </a>
          </div>
          <ul className="menu">
            <li>
              <a href="https://www.sennheiser-hearing.com/en-US/headphones/">Headphones &amp; Soundbars</a>
            </li>
            <li>
              <a href="https://en-us.sennheiser.com/pro-audio-microphones-wireless-systems-iem-headphones">Pro Audio</a>
            </li>
            <li>
              <a href="https://en-us.sennheiser.com/business">Business</a>
            </li>
            <li>
              <a href="https://en-us.sennheiser.com/service-support">Support</a>
            </li>
          </ul>
        </section>
      </div>
    </header>
  )
}

export default Header
