import './Error.scss'

function Error(props) {
  const { message, onClose } = props

  return (
    <div className="error" onClick={() => onClose && onClose()}>
      <span className="close" />
      {message}
    </div>
  )
}

export default Error
