import './index.scss'
import ChannelList from './ChannelList'
import Circles from './Circles'
import Recommendations from './Recommendations'

function Results(props) {
  const { data, onBack, searchParams } = props,
        { tv_channels, recommendations } = data,
        usable_num = Object.values(tv_channels)
          .reduce((sum, ch) => ch.status === 'Usable' || ch.status === 'Vacant' ? sum + 1 : sum, 0)

  return (
    <div className="results wrapper">
      <div className="results-title">Results</div>
      <div className="heading-usable">
        { usable_num > 1 &&
          <>There are <span className="usable-number">{usable_num}</span> </> }
        { usable_num === 1 &&
          <>There is <span className="usable-number">1</span> </> }
        { usable_num === 0 &&
          <>There are NO </> }
        usable 6 MHz wide DTV channel{ usable_num !== 1 && "s" }.
      </div>
      <div className="results-logo" />

      <Circles channels={tv_channels} />

      { usable_num > 0 &&
        <Recommendations data={recommendations} searchParams={searchParams} /> }

      <ChannelList channels={tv_channels} />

      <div className="disclaimer">
        Disclaimer – Other interference may reduce channel count. This may vary by location and time.
      </div>

      <button
        className="back-button"
        onClick={() => onBack && onBack()}>
        &lt; Back to Search
      </button>
    </div>
  )
}

export default Results
