import './Circles.scss'

function Circles(props) {
  const { channels } = props,
        filteredChannelNums = Object.keys(channels)
                                .filter((ch_n) => ["Vacant", "Usable"].includes(channels[ch_n].status))

  if(filteredChannelNums.length > 10) {
    return (
      <div className="circles-placeholder" />
    )
  }

  return (
    <div className="circles">
      <ol>
        { filteredChannelNums.map((ch_n) => {
            const ch = channels[ch_n],
                  erp = ch.interfering_stations.reduce((res, st) => res === null || st.erp_kw > res ? st.erp_kw : res , null)

            return (
              <li
                key={ch_n}
                className={ch.status.toLowerCase()}>

                <svg className="inner-circle" viewBox="0 0 59 59">
                  <text
                    className={"number " + ch.status.toLowerCase()}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    x="29"
                    y="27">
                    {ch_n}
                  </text>
                  <text
                    className={"status " + ch.status.toLowerCase()}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    x="29"
                    y="43">
                    { (ch.status === "Usable" && (erp && "ERP: " + erp + "kW")) ||
                       ch.status }
                  </text>
                </svg>
              </li>
            )
          })}
      </ol>
    </div>
  )
}

export default Circles
