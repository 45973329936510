import { useState } from 'react'
import './App.scss'
import Header from './header'
import SearchForm from './search_form'
import Error from './Error'
import Results from './results'

function App() {
  const [data, setData] = useState(),
        [error, setError] = useState(),
        [showSpinner, setShowSpinner] = useState(false),
        [showResults, setShowResults] = useState(false),
        [searchParams, setSearchParams] = useState()

  const handleOnSubmit = (params) => {
    const query = new URLSearchParams()

    setSearchParams(params)
    setShowSpinner(true)
    setError(null)
    setData(null)
    setShowResults(false)

    for(const key in params) query.append(key, params[key])

    fetch(process.env.REACT_APP_API_URL + "?" + query.toString())
      .then(res => res.json())
      .then(
        (result) => {
          setShowSpinner(false)
          setData(result)
          setShowResults(true)
        },
        (error) => {
          setShowSpinner(false)
          setError(error.message)
        }
      )
  }

  return (
    <div className="App">
      <div className={"form-page" + (showResults ? " hide" : "")}>
        <Header />
        <SearchForm onSubmit={handleOnSubmit} />
      </div>
      { error &&
        <Error
          message={"Error occured: " + error + ". Please try again."}
          onClose={() => setError(null)} /> }
      { showSpinner &&
        <div className="spinner-modal"><div className="spinner" /></div> }
      <div className={"result-page" + (showResults ? "" : " hide")}>
        { data &&
          <Results
            data={data}
            searchParams={searchParams}
            onBack={() => setShowResults(false)}/> }
      </div>
    </div>
  )
}

export default App
