import { useState } from 'react'
import Geocoder from './Geocoder'
import AdvancedOptions from './AdvancedOptions'
import './index.scss'

const DEFAULT_VALUES = { series: "ewdx", attenuation: 40, radius: 55 }

function SearchForm(props) {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false),
        [coordinates, setCoordinates] = useState(),
        [values, setValues] = useState(DEFAULT_VALUES),
        { onSubmit } = props

  const handleOnSubmit = (e) => {
    e.preventDefault()
    onSubmit && onSubmit({ ...coordinates, ...values })
  }

  return (
    <div className="search-form-container">
      <div className="search-form">
        <div className="title">
          Frequency Finder
        </div>
        <div className="subtitle">
          US Edition
        </div>
        <form>
          <div className="input-group">
            <label>Location</label>
            <Geocoder onChange={setCoordinates} />
          </div>
          <div className="input-group">
            <label htmlFor="series">Product Series</label>
            <select
              name="series"
              className="form-control"
              value={values["series"]}
              onChange={(e) => setValues({ ...values, series: e.target.value })} >
              <option value="ewd">EW-D/EW-DP</option>
              <option value="ewdx">EW-DX</option>
              <option value="d6000">Digital 6000 Series</option>
            </select>
          </div>
          { !showAdvancedOptions &&
            <div className="advanced-options-button">
              <button type="button" onClick={() => setShowAdvancedOptions(true)}>
                Advanced Options
              </button>
            </div> }
          { showAdvancedOptions &&
            <AdvancedOptions
              onChange={(option) => setValues({ ...values, ...option })}
              values={values} /> }
          <div className="get-results-button">
            <button
              type="submit"
              onClick={handleOnSubmit}
              disabled={!coordinates} >
              Submit - Get Results
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SearchForm
